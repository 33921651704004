// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { useOnboarding } from 'admin/onboarding/containers/OnboardingButtonWithDialog';
import {
    IOnboardingStepState,
    OnboardingStep,
} from 'common/api/resources/Onboarding';
import {
    OnboardingSidebarHeader,
    OnboardingStepperCheckbox,
    OnboardingStepperContainer,
    OnboardingStepperStepWrapper,
} from 'admin/onboarding/components/Styled';
import { Translate } from '@plesk/ui-library';
import { PopupButton } from '@typeform/embed-react';
import { Button } from 'admin/common/components/Button/Button';
import { SIZE } from 'common/constants';

export interface IOnboardingStepperStepProps {
    children: React.ReactNode;
    variant: 'active' | 'completed' | 'disabled';
}

export const OnboardingStepperStep: React.FC<IOnboardingStepperStepProps> = ({
    children,
    variant,
}) => (
    <OnboardingStepperStepWrapper $variant={variant}>
        {children}

        <OnboardingStepperCheckbox
            disabled={variant === 'disabled'}
            defaultChecked={variant === 'completed'}
            readOnly
        />
    </OnboardingStepperStepWrapper>
);

export function getStepState(
    step: OnboardingStep,
    currentStep: OnboardingStep,
    allSteps: Record<OnboardingStep, IOnboardingStepState>
): 'active' | 'completed' | 'disabled' {
    if (step === currentStep) {
        return 'active';
    }

    if (allSteps[step].submitted) {
        return 'completed';
    }

    return 'disabled';
}

export interface IOnboardingStepperProps {}

export const OnboardingStepper: React.FC<IOnboardingStepperProps> = () => {
    const {
        step: currentStep,
        allSteps,
    } = useOnboarding();

    // @ts-ignore
    return (
        <OnboardingStepperContainer>
            <OnboardingSidebarHeader>
                <Translate content={'onboarding.clusterSetup.header'}/>
            </OnboardingSidebarHeader>

            <ul>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.ACTIVATE_LICENSE, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.activateLicense.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.ADD_COMPUTE_RESOURCE, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addComputeResource.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.CONNECT_COMPUTE_RESOURCE, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.connectComputeResource.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.ADD_IP_BLOCK, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addIpBlock.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.ADD_VM_PLAN, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.addVmPlan.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.MAIL_SETTINGS, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.mailSettings.header'}/>
                </OnboardingStepperStep>
                <OnboardingStepperStep
                    variant={getStepState(OnboardingStep.CREATE_FIRST_VM, currentStep, allSteps)}
                >
                    <Translate content={'onboarding.clusterSetup.steps.createFirstVm.header'}/>
                </OnboardingStepperStep>
            </ul>

            <PopupButton
                id={'ejsXRZO7'} /* cspell:disable-line */
                // @ts-ignore
                as={Button}
                buttonProps={{
                    // @ts-ignore
                    size: SIZE.LG,
                    intent: 'primary',
                }}
                style={{
                    marginTop: 'auto',
                }}
                size={60}
                height={50}
            >
                <Translate content={'onboarding.clusterSetup.helpForm'} />
            </PopupButton>
        </OnboardingStepperContainer>
    );
};
