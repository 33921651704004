// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import React, { useEffect } from 'react';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Translate } from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    getOnboardingClusterSetup,
    updateOnboardingClusterSetupStep,
} from 'admin/onboarding/actions';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import OnboardingContent from 'admin/onboarding/containers/OnboardingContent';
import {
    getComputeResource,
    unsetItem,
} from 'admin/computeResource/actions';
import { OnboardingModal } from 'admin/onboarding/components/Styled';
import {
    OnboardingStep,
    IOnboardingStepState,
} from 'common/api/resources/Onboarding';
import { getLocations } from 'common/modules/location/actions';
import { getMeta } from 'common/modules/app/meta/actions';
import { setIpBlockItem } from 'admin/ipBlock/actions';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { IAxiosApiResponse } from 'common/api/resources/Response';
import { initialIpBlockState } from 'admin/ipBlock/reducer';
import * as Sentry from '@sentry/browser';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export interface IOnboardingButtonWithDialogProps {
}

export type OnboardingButtonWithDialogProps = IOnboardingButtonWithDialogProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export interface IOnboardingContextValue {
    close: () => void;
    skip: () => Promise<void>;
    step: OnboardingStep;
    allSteps: Record<OnboardingStep, IOnboardingStepState>;
    computeResourceId: number|null;
    updateOnboardingState: (...args: Parameters<typeof updateOnboardingClusterSetupStep>) => Promise<void>;
    shouldAutoOpen?: boolean;
}

export const OnboardingContext = React.createContext<IOnboardingContextValue|undefined>(undefined);

export const useOnboarding = () => {
    const context = React.useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboarding must be used within a OnboardingProvider');
    }
    return context;
};

export const OnboardingButtonWithDialog: React.FC<OnboardingButtonWithDialogProps> = ({
    isOnboardingLoading,
    loadOnboardingState,
    loadComputeResource,
    loadLocations,
    unsetComputeResource,
    onboarding,
    reloadMeta,
    updateOnboardingState: updateOnboardingStateApi,
    setIpBlock,
    shouldAutoOpen = false,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const updateOnboardingState = React.useMemo(() => async (...args: Parameters<typeof updateOnboardingClusterSetupStep>) => {
        try {
            await updateOnboardingStateApi(...args);
            await reloadMeta();
        } catch (e) {
            Sentry.captureException(e, {
                tags: {
                    feature: 'onboarding',
                },
            });
            throw e;
        }
    }, [updateOnboardingStateApi, reloadMeta]);

    const openInitialOnboarding = React.useCallback(async () => {
        setIsOpen(true);
        await updateOnboardingState(OnboardingStep.WELCOME);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsOpen]);

    useEffect(() => {
        if (shouldAutoOpen) {
            openInitialOnboarding();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        unsetComputeResource();
        Promise.all([
            loadOnboardingState(),
            loadLocations(),
        ]);
    }, [unsetComputeResource, loadOnboardingState, loadLocations]);

    React.useEffect(() => {
        if (onboarding.compute_resource_id !== null && isOpen) {
            const loadCreatedComputeResource = async (id: number) => {
                const response: IAxiosApiResponse<IComputeResourceResponse> = await loadComputeResource(id);
                const createdComputeResource = response.data.data;

                setIpBlock({
                    ...initialIpBlockState,
                    compute_resources: [createdComputeResource],
                });
            };

            loadCreatedComputeResource(onboarding.compute_resource_id);
        }
    }, [onboarding.compute_resource_id, loadComputeResource, isOpen, setIpBlock]);

    const handleClose = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleSkip = React.useCallback(async () => {
        handleClose();
        await updateOnboardingState(OnboardingStep.SKIP);
        await reloadMeta();
    }, [updateOnboardingState, handleClose, reloadMeta]);

    const onboardingContext = React.useMemo<IOnboardingContextValue>(() => ({
        close: handleClose,
        skip: handleSkip,
        step: onboarding.step,
        allSteps: onboarding.all_steps,
        computeResourceId: onboarding.compute_resource_id,
        updateOnboardingState,
    }), [updateOnboardingState, handleClose, handleSkip, onboarding]);

    return (
        <>
            <Button
                size={SIZE.MD}
                intent={INTENT_TYPE.PRIMARY}
                icon={'rocket'}
                onClick={() => setIsOpen(true)}
            >
                <Translate content={'onboarding.clusterSetup.startBtn'}/>
            </Button>
            <OnboardingModal
                $showPadding={isOnboardingLoading}
                isOpen={isOpen}
                size={SIZE.XL}
                onClose={() => setIsOpen(false)}
            >
                <OnboardingContext.Provider value={onboardingContext}>
                    <Loader isLoading={isOnboardingLoading} center={false}>
                        {!isOnboardingLoading && <OnboardingContent /> }
                    </Loader>
                </OnboardingContext.Provider>
            </OnboardingModal>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    onboarding: state.onboarding,
    shouldAutoOpen: hasPermission(state, PERMISSION_LIST.MANAGE_APP_SETTINGS)
        && state.app.meta.clusterOnboarding === OnboardingStep.INITIAL,
    isOnboardingLoading: state.app.loadingFlags.has(LOADING_FLAGS.ONBOARDING_CLUSTER_SETUP)
        || state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_ITEM)
        || state.app.loadingFlags.has(LOADING_FLAGS.LOCATION_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    reloadMeta: bindActionCreators(getMeta, dispatch),
    loadOnboardingState: bindActionCreators(getOnboardingClusterSetup, dispatch),
    loadComputeResource: bindActionCreators(getComputeResource, dispatch),
    loadLocations: bindActionCreators(getLocations, dispatch),
    unsetComputeResource: bindActionCreators(unsetItem, dispatch),
    updateOnboardingState: bindActionCreators(updateOnboardingClusterSetupStep, dispatch),
    setIpBlock: bindActionCreators(setIpBlockItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingButtonWithDialog);